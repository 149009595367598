/*#components-layout-demo-custom-trigger .trigger {*/
/*font-size: 18px;*/
/*line-height: 64px;*/
/*padding: 0 24px;*/
/*cursor: pointer;*/
/*transition: color .3s;*/
/*}*/

/*#components-layout-demo-custom-trigger .trigger:hover {*/
/*color: #1890ff;*/
/*}*/

/*#components-layout-demo-custom-trigger .logo {*/
/*height: 32px;*/
/*background: rgba(255,255,255,.2);*/
/*margin: 16px;*/
/*}*/
.logo {
    height: 32px;
    background: rgba(255, 255, 255, .2);
    margin: 16px;
}

.ant-menu-item {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #1890ff !important;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 6px 10px;
    word-break: inherit !important;
}

.ant-table-tbody .ant-form-item{
    margin-bottom: initial !important;
}

.ant-table{
    overflow-x: scroll;
}

.ant-checkbox-wrapper {
     margin-left: 8px;
}

.ant-tabs-nav .ant-tabs-tab{
    margin: 0 5px 0 0;
}
.ant-tabs-bar.ant-tabs-card-bar {
    margin: 0 0 0 0;
}
.ant-form-item{
    margin-bottom: 12px;
}
.ant-form-item-control{
    line-height: 30px;
}

.ant-layout-sider{
    display: none;
}
@media(min-width:700px){
    .responsive-drawer-menu{
        display:none;
    }
    .ant-layout-sider{
        display: block;
    }
}
@media(max-width:575px){
    .ant-card-head-wrapper{
        margin-top:55px;
    }
}
